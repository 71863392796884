import React from 'react';
import { FaDiscord, FaYoutube, FaTwitch, FaFacebook, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import './About.css'; // Updated CSS

const AboutUs = () => {
    return (
        <div className="about-us">
            {/* About Team Lucifer Section */}
            <section className="about-team-lucifer">
                <div className="about-team-lucifer-container">
                    <div className="about-team-lucifer-title">
                        <h1>About Team Lucifer</h1>
                    </div>
                    <div className="about-team-lucifer-content">
                        <p>Welcome to Team Lucifer, where gaming meets community! Our mission is to create an inclusive environment for gamers of all skill levels to come together and compete.</p>
                    </div>
                </div>
            </section>

            {/* Our History Section */}
            <section className="our-history">
                <div className="our-history-container">
                    <div className="our-history-title">
                        <h2>Our History</h2>
                    </div>
                    <div className="our-history-content">
                        <p>Team Lucifer was formed in 2024 with a vision to dominate the esports scene and provide a platform for gamers to showcase their skills. Since then, we have a few successful tournaments and built a thriving community.</p>
                    </div>
                </div>
            </section>

            {/* Our Values Section */}
            <section className="our-values">
                <div className="our-values-container">
                    <div className="our-values-title">
                        <h2>Our Values</h2>
                    </div>
                    <div className="our-values-content">
                        <ul className="values-list">
                            <li>Teamwork: We believe in the power of collaboration.</li>
                            <li>Fair Play: We uphold the principles of sportsmanship.</li>
                            <li>Inclusivity: Everyone is welcome, regardless of skill level.</li>
                            <li>Community Engagement: We strive to give back to our community.</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Meet the Team Section */}
            <section className="meet-the-team">
                <div className="meet-the-team-container">
                    <div className="meet-the-team-title">
                        <h2>Meet the Team</h2>
                    </div>
                    <div className="meet-the-team-content">
                        <div className="team-grid">
                            <div className="team-member">
                                <div className="team-image" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2Fluciferv0.jpg?alt=media&token=080bd698-ca12-43ed-9899-ad46268a0201')` }}>
                                    <div className="about-team-info">
                                        <h3>Lucif3r</h3>
                                        <p>Team Leader</p>
                                        <a href="https://api.whatsapp.com/send/?phone=64224234181&text&type=phone_number&app_absent=0" className="team-contact-btn" target="_blank" rel="noreferrer">Contact</a>
                                    </div>
                                </div>
                            </div>
                            <div className="team-member">
                                <div className="team-image" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2Fsahan2.png?alt=media&token=b115cecc-f72e-4fad-9db2-3efbfbed8db2')` }}>
                                    <div className="about-team-info">
                                        <h3>Moshi</h3>
                                        <p>Content Creator</p>
                                        <a href="https://api.whatsapp.com/send/?phone=94781440894&text&type=phone_number&app_absent=0" className="team-contact-btn" target="_blank" rel="noreferrer">Contact</a>
                                    </div>
                                </div>
                            </div>
                            <div className="team-member">
                                <div className="team-image" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2Fsakith.jpg?alt=media&token=5664f850-7e47-47bc-bbaf-471a6a62478c')` }}>
                                    <div className="about-team-info">
                                        <h3>Sakith</h3>
                                        <p>Tournament Organizer</p>
                                        <a href="https://api.whatsapp.com/send/?phone=94770181562&text&type=phone_number&app_absent=0" className="team-contact-btn" target="_blank" rel="noreferrer">Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Future Goals Section */}
            <section className="future-goals">
                <div className="future-goals-container">
                    <div className="future-goals-title">
                        <h2>Future Goals</h2>
                    </div>
                    <div className="future-goals-content">
                        <p>As we move forward, we aim to expand our reach, host more tournaments, and continue building a supportive community.</p>
                    </div>
                </div>
            </section>

            {/* Join Us Section */}
            <section className="join-us">
                <div className="join-us-container">
                    <div className="join-us-title">
                        <h2>Join Us</h2>
                    </div>
                    <div className="join-us-content">
                        <p>Follow us on our social media platforms and join our Discord channel to stay updated and become part of our community!</p>
                        <a href="https://discord.gg/gDWZ3ruR" className="cta-button" target="_blank" rel="noreferrer">Join Our Discord</a>
                        <div className="social-media-icons">
                            <a href="https://chat.whatsapp.com/Ck2G1UgWJ7v1G7c827btwC" className="social-icon Whatsapp" target="_blank" rel="noreferrer"><FaWhatsapp /></a>
                            <a href="https://www.youtube.com/@SL_Lucif3R" className="social-icon youtube" target="_blank" rel="noreferrer"><FaYoutube /></a>
                            <a href="https://www.twitch.tv/sl_lucif3r" className="social-icon twitch" target="_blank" rel="noreferrer"><FaTwitch /></a>
                            <a href="https://web.facebook.com/watch/SLLucif3R/" className="social-icon facebook" target="_blank" rel="noreferrer"><FaFacebook /></a>
                            <a href="https://www.tiktok.com/@sllucif3r" className="social-icon tiktok" target="_blank" rel="noreferrer"><FaTiktok /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;
