import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Update with the path to your Firebase config
import './TeamBannerDef.css';

function TeamBannerDef({ imageUrl }) {
  const [teamData, setTeamData] = useState({
    teamLogo: '', // Match with your Firestore field names
    teamName: '',
    rounds: '',
  });

  useEffect(() => {
    const teamDocRef = doc(db, 'overlay', 'defend'); // Adjust document path as needed
    const unsubscribe = onSnapshot(teamDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setTeamData(docSnapshot.data());
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div 
      className="team-banner-def" 
      style={{
        width: '512px',
        height: '77px',
        position: 'absolute',
        top: '11px',
        left: '1039px',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
      }}
    >
      <div className="content-wrapper-def">
        <div className="team-logo-overlay">
          {/* Check if the URL exists before trying to display it */}
          {teamData.teamLogo ? (
            <img 
              src={teamData.teamLogo} 
              alt={`${teamData.teamName} logo`} 
              onError={(e) => { e.target.src = ''; }} // Handle broken image links
            />
          ) : (
            <span>Logo not available</span> // Placeholder text if no logo URL
          )}
        </div>
        <div className="team-name">{teamData.teamName}</div>
        <div className="round-count">{teamData.rounds}</div>
      </div>
    </div>
  );
}

export default TeamBannerDef;
