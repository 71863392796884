// src/pages/Tournaments.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Tournaments.css';
import { getAuth } from 'firebase/auth';

const Tournaments = () => {
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInTeam, setIsInTeam] = useState(false);
  const [userTeamID, setUserTeamID] = useState(null);
  const [registeredTournamentIDs, setRegisteredTournamentIDs] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      const querySnapshot = await getDocs(collection(db, 'Tournaments'));
      const tournamentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTournaments(tournamentList);
    };

    const fetchUserTeamStatus = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userUID = user.uid;
        const userDocRef = doc(db, 'LoginDetails', userUID);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setIsInTeam(userData.InTeam);
          setUserTeamID(userData.TeamID);

          const registeredTournaments = await getDocs(collection(db, 'Tournaments'));
          const registeredTournamentIDs = [];
          registeredTournaments.forEach(docSnapshot => {
            const tournamentData = docSnapshot.data();
            const registeredTeams = tournamentData.registeredTeams || [];
            if (registeredTeams.includes(userData.TeamID)) {
              registeredTournamentIDs.push(docSnapshot.id);
            }
          });
          setRegisteredTournamentIDs(registeredTournamentIDs);
        }
      }
    };

    fetchTournaments();
    fetchUserTeamStatus();
  }, []);

  const handleRegister = async (tournamentId) => {
    setIsLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;

    if (user && userTeamID) {
      const tournamentRef = doc(db, 'Tournaments', tournamentId);
      const tournamentSnapshot = await getDoc(tournamentRef);

      if (tournamentSnapshot.exists()) {
        const tournamentData = tournamentSnapshot.data();
        const registeredTeams = tournamentData.registeredTeams || [];

        if (registeredTeams.includes(userTeamID)) {
          alert('Your team is already registered for this tournament.');
          setIsLoading(false);
          return;
        }

        const updatedTeams = [...registeredTeams, userTeamID];

        await updateDoc(tournamentRef, {
          registeredTeams: updatedTeams,
          registeredTeamsCount: updatedTeams.length.toString(),
        });

        setRegisteredTournamentIDs((prev) => [...prev, tournamentId]);
        alert('Team registered successfully!');
      }
    }
    setIsLoading(false);
  };

  const getButtonTextAndClass = (status, tournamentId) => {
    if (!isInTeam) {
      return {
        text: 'Join a Team to Register',
        className: 'register-btn disabled', // Add a class for disabled styling
      };
    }

    if (status.toLowerCase() === 'upcoming') {
      return {
        text: registeredTournamentIDs.includes(tournamentId) ? 'Registered!' : (isLoading ? 'Registering...' : 'Register Team'),
        className: 'register-btn',
      };
    } else if (status.toLowerCase() === 'ongoing' || status.toLowerCase() === 'completed') {
      return {
        text: 'Closed',
        className: 'register-btn closed',
      };
    }
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'upcoming':
        return 'upcoming';
      case 'ongoing':
        return 'ongoing';
      case 'completed':
        return 'completed';
      default:
        return '';
    }
  };

  return (
    <div className="tournaments-page">
      <div className="tournaments-hero">
        <h1>Upcoming Tournaments</h1>
        <p>Join the battle and compete with the best!</p>
      </div>
      <div className="tournaments-grid">
        {tournaments.length > 0 ? (
          tournaments.map((tournament) => {
            const { text, className } = getButtonTextAndClass(tournament.Status, tournament.id);
            return (
              <div className="tournament-card" key={tournament.id}>
                {/* Tournament Status Label */}
                <div className={`status-label ${getStatusClass(tournament.Status)}`}>
                  {tournament.Status}
                </div>

                <div className="tournament-status">
                  <div className={`status-dot ${tournament.Status.toLowerCase()}`}></div>
                </div>

                <Link to={`/tournament/${tournament.id}`}>
                  <img src={tournament.BannerImage} alt={tournament.TournamentTitle} className="tournament-banner" />
                </Link>

                <div className="tournament-info">
                  <h3 className="tournament-card-title">{tournament.TournamentTitle}</h3>
                  <table className="tournament-details">
                    <tbody>
                      <tr>
                        <td><strong>Organizer:</strong></td>
                        <td>{tournament.Organiser}</td>
                      </tr>
                      <tr>
                        <td><strong>Prize Pool:</strong></td>
                        <td>{tournament.PrizePool}</td>
                      </tr>
                      <tr>
                        <td><strong>Starting Date:</strong></td>
                        <td>{tournament.EventStartDate}</td>
                      </tr>
                      <tr>
                        <td><strong>End Date:</strong></td>
                        <td>{tournament.EventEndDate}</td>
                      </tr>
                      <tr>
                        <td><strong>Registration Closing Date:</strong></td>
                        <td>{tournament.RegistrationClosingDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="tournament-footer">
                    <span>{tournament.registeredTeamsCount} Teams registered!</span>
                    <button 
                      className={className}
                      onClick={() => {
                        if (tournament.Status.toLowerCase() === 'upcoming' && isInTeam) {
                          handleRegister(tournament.id);
                        }
                      }}
                      disabled={className.includes('disabled') || className.includes('closed')}
                    >
                      {text}
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No tournaments available at the moment.</p>
        )}
      </div>
    </div>
  );
};

export default Tournaments;
