import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Make sure to import your Firebase configuration
import { FaTrashAlt, FaEdit } from 'react-icons/fa'; // Icons for Edit and Delete
import './ViewTournamentsPopup.css';

const ViewTournamentsPopup = ({ tournaments, onClose, onDelete }) => {
  const [editingTournament, setEditingTournament] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [registeredTeams, setRegisteredTeams] = useState([]);
  const [showRegisteredTeamsPopup, setShowRegisteredTeamsPopup] = useState(false);

  // Fetch registered teams when the popup opens
  useEffect(() => {
    if (editingTournament) {
      setUpdatedData(editingTournament); // Set the current tournament data for editing
    }
  }, [editingTournament]);

  // Handle tournament edit submission
  const handleUpdateTournament = async (id) => {
    const tournamentRef = doc(db, 'Tournaments', id);
    await updateDoc(tournamentRef, updatedData);
    setEditingTournament(null);
    setUpdatedData({});
  };

  // Handle input change for editing
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch registered teams from Firestore
  const handleRegisteredTeamsClick = async (registeredTeamsUids) => {
    const teams = [];
    for (const uid of registeredTeamsUids) {
      const teamRef = doc(db, 'teams', uid);
      const teamDoc = await getDoc(teamRef);
      if (teamDoc.exists()) {
        teams.push({ id: uid, ...teamDoc.data() });
      }
    }
    setRegisteredTeams(teams);
    setShowRegisteredTeamsPopup(true);
  };

  return (
    <div className="popup-overlay">
      <div className="tournament-edit-popup-content">
        {editingTournament ? (
          <div className="popup-content">
          <h2 className="tournament-popup-title">Edit Tournament</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateTournament(editingTournament.id);
            }}
            className="tournament-popup-form"
          >
            <div className="tournament-popup-field">
              <label>
                Tournament Title
                <input
                  type="text"
                  name="TournamentTitle"
                  defaultValue={editingTournament.TournamentTitle}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Organiser
                <input
                  type="text"
                  name="Organiser"
                  defaultValue={editingTournament.Organiser}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Game
                <select
                  name="Game"
                  defaultValue={editingTournament.Game}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Valorant">Valorant</option>
                  <option value="CS:GO">CS:GO</option>
                  <option value="Dota 2">Dota 2</option>
                  <option value="League of Legends">League of Legends</option>
                </select>
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Start Date
                <input
                  type="date"
                  name="EventStartDate"
                  defaultValue={editingTournament.EventStartDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Start Time
                <input
                  type="time"
                  name="EventStartTime"
                  defaultValue={editingTournament.EventStartTime}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                End Date
                <input
                  type="date"
                  name="EventEndDate"
                  defaultValue={editingTournament.EventEndDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Prize Pool
                <input
                  type="text"
                  name="PrizePool"
                  defaultValue={editingTournament.PrizePool}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Registration Closing Date
                <input
                  type="date"
                  name="RegistrationClosingDate"
                  defaultValue={editingTournament.RegistrationClosingDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Status
                <select
                  name="Status"
                  defaultValue={editingTournament.Status}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Upcoming">Upcoming</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="Completed">Completed</option>
                </select>
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Team Limit
                <input
                  type="number"
                  name="TeamLimit"
                  defaultValue={editingTournament.TeamLimit}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-field">
              <label>
                Description
                <textarea
                  name="Description"
                  defaultValue={editingTournament.Description}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="tournament-popup-footer">
              <button type="submit" className="save-button">Save Changes</button>
              <button type="button" className="close-button" onClick={() => setEditingTournament(null)}>Close</button>
            </div>
          </form>
        </div>
        ) : (
          <div className="popup-content">
            <h2 className="tournament-popup-title">Current Tournaments</h2>
            <ul className="tournament-list">
              {tournaments.map((tournament) => (
                <li key={tournament.id} className="tournament-item">
                  <img src={tournament.BannerImage} alt={tournament.TournamentTitle} className="banner-thumbnail" />
                  <span>{tournament.TournamentTitle}</span>
                  <div className="tournament-buttons">
                    <button className="edit-button" onClick={() => setEditingTournament(tournament)}>
                      <FaEdit />
                    </button>
                    <button className="delete-button" onClick={() => onDelete(tournament.id)}>
                      <FaTrashAlt />
                    </button>
                    <button 
                      className="registered-teams-button" 
                      onClick={() => handleRegisteredTeamsClick(tournament.registeredTeams)}
                    >
                      Registered Teams
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <button className="edit-close-button" onClick={onClose}>Close</button>
          </div>
        )}
      </div>
      {showRegisteredTeamsPopup && (
        <div className="registered-teams-popup-overlay">
          <div className="registered-teams-popup-content">
            <h2 className="registered-teams-title">Registered Teams</h2>
            <div className="registered-teams-grid">
              {registeredTeams.map((team) => (
                <div key={team.id} className="reg-team-card">
                  <img src={team.teamLogo} alt={team.teamName} className="team-logo" />
                  <h3>{team.teamName}</h3>
                  <p>Avg Rank: {team.avgRank}</p>
                  <p>Discord: {team.TLDiscord}</p>
                </div>
              ))}
            </div>
            <button className="reg-close-button" onClick={() => setShowRegisteredTeamsPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTournamentsPopup;
