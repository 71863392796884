import React, { useState } from 'react';
import './AddTeamPopup.css';
import { db, storage } from '../firebaseConfig';
import { collection, doc, setDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const AddTeamPopup = ({ onClose, onTeamCreated }) => {
  const navigate = useNavigate(); // Initialize navigate hook
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGame, setSelectedGame] = useState('');
  const [teamLogo, setTeamLogo] = useState(null);
  const [teamName, setTeamName] = useState('');
  const [tlDiscord, setTlDiscord] = useState('');
  const [players, setPlayers] = useState(
    Array(6).fill().map(() => ({ email: '', phone: '', rank: '', riotId: '', trackerLink: '' }))
  );
  const [rulesAccepted, setRulesAccepted] = useState(false);

  const rankMapping = {
    "Iron 1": 1, "Iron 2": 2, "Iron 3": 3,
    "Bronze 1": 4, "Bronze 2": 5, "Bronze 3": 6,
    "Silver 1": 7, "Silver 2": 8, "Silver 3": 9,
    "Gold 1": 10, "Gold 2": 11, "Gold 3": 12,
    "Platinum 1": 13, "Platinum 2": 14, "Platinum 3": 15,
    "Diamond 1": 16, "Diamond 2": 17, "Diamond 3": 18,
    "Ascendent 1": 19, "Ascendent 2": 20, "Ascendent 3": 21,
    "Immortal 1": 22, "Immortal 2": 23, "Immortal 3": 24,
    "Radiant": 25
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `teamLogos/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        setTeamLogo(url);
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  const handlePlayerChange = (index, e) => {
    const { name, value } = e.target;
    const newPlayers = [...players];
    newPlayers[index] = { ...newPlayers[index], [name]: value };
    setPlayers(newPlayers);
  };

  const handleNext = () => {
    if (validateCurrentPage()) {
      setCurrentPage((prev) => Math.min(prev + 1, 3));
    }
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const validateCurrentPage = () => {
    if (currentPage === 1) {
      return selectedGame && teamLogo && teamName && tlDiscord;
    }
    if (currentPage === 2) {
      return players.slice(0, 5).every(player => 
        player.riotId && player.email && player.phone && player.rank && player.trackerLink
      );
    }
    return true;
  };

  const calculateAvgRank = () => {
    const rankNumbers = players.slice(0, 5).map(player => rankMapping[player.rank]);
    const avgRankNumber = Math.round(rankNumbers.reduce((sum, rank) => sum + rank, 0) / 5);
    return Object.keys(rankMapping).find(key => rankMapping[key] === avgRankNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    const avgRank = calculateAvgRank();

    const teamData = {
      teamLogo,
      teamName,
      TLDiscord: tlDiscord,
      players: players.map(player => ({
        email: player.email,
        phone: player.phone,
        rank: player.rank,
        riotId: player.riotId,
        trackerLink: player.trackerLink,
      })),
      matchWins: 0,
      tournamentWins: 0,
      avgRank,
    };

    try {
      if (user) {
        const teamDocRef = doc(db, 'teams', user.uid); 
        await setDoc(teamDocRef, teamData); 
        onTeamCreated(teamData);

        // Update user login details to set InTeam and TeamID
        const userDocRef = doc(db, 'LoginDetails', user.uid);
        await setDoc(userDocRef, {
          InTeam: true,
          TeamID: user.uid
        }, { merge: true });

        navigate('/tournaments'); // Redirect to Tournaments page
      } else {
        alert('No user logged in.');
      }
    } catch (error) {
      console.error('Error submitting team data:', error);
    }
  };

  return (
    <div className="add-team-popup-overlay">
      <div className="add-team-popup-content">
        <div className="add-team-popup-header">
          <h2>Add Your Team</h2>
          <button className="add-team-popup-close-btn" onClick={onClose}>X</button>
        </div>
        <form onSubmit={handleSubmit} className="add-team-popup-form">
          <div className="add-team-popup-form-section">
            {currentPage === 1 && (
              <>
                <div className="add-team-popup-select-game">
                  <label>Select Game</label>
                  <select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)} required>
                    <option value="">Select a game</option>
                    <option value="Valorant">Valorant</option>
                  </select>
                </div>
                <div className="add-team-popup-team-logo">
                  <label>Team Logo</label>
                  <input type="file" accept="image/*" onChange={handleLogoUpload} required />
                </div>
                <div className="add-team-popup-team-name">
                  <label>Team Name</label>
                  <input
                    type="text"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    required
                  />
                </div>
                <div className="add-team-popup-tl-discord">
                  <label>Team Leader's Discord</label>
                  <input
                    type="text"
                    value={tlDiscord}
                    onChange={(e) => setTlDiscord(e.target.value)}
                    required
                  />
                </div>
              </>
            )}
            {currentPage === 2 && (
              <div className="add-team-popup-players-grid">
                {players.map((player, index) => (
                  <div key={index} className="add-team-popup-player-input">
                    <h3 className="add-team-popup-player-title">Player {index + 1}</h3>
                    <input type="text" name="riotId" className="add-team-popup-input" placeholder="Riot ID" value={player.riotId} onChange={(e) => handlePlayerChange(index, e)} required />
                    <input type="email" name="email" className="add-team-popup-input" placeholder="Email" value={player.email} onChange={(e) => handlePlayerChange(index, e)} required />
                    <input type="text" name="phone" className="add-team-popup-input" placeholder="Phone" value={player.phone} onChange={(e) => handlePlayerChange(index, e)} required />
                    <select name="rank" className="custom-select" value={player.rank} onChange={(e) => handlePlayerChange(index, e)} required>
                      <option value="">Select rank</option>
                      {Object.keys(rankMapping).map((rank) => (
                        <option key={rank} value={rank}>{rank}</option>
                      ))}
                    </select>
                    <input type="text" name="trackerLink" className="add-team-popup-input" placeholder="Tracker Link" value={player.trackerLink} onChange={(e) => handlePlayerChange(index, e)} required />
                  </div>
                ))}
              </div>
            )}
            {currentPage === 3 && (
              <div className="add-team-popup-rules">
                <h3>Rules and Regulations</h3>
                <p>Ensure all team members are aware of the tournament rules.<br />Agree to the terms to proceed.</p>
                <label>
                  <input type="checkbox" checked={rulesAccepted} onChange={(e) => setRulesAccepted(e.target.checked)} required />
                  I accept the tournament rules.
                </label>
              </div>
            )}
          </div>
          <div className="add-team-popup-button-group">
            {currentPage > 1 && (
              <button type="button" onClick={handlePrevious}>Previous</button>
            )}
            {currentPage < 3 ? (
              <button type="button" onClick={handleNext}>Next</button>
            ) : (
              <button type="submit" disabled={!rulesAccepted}>Finish</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeamPopup;
