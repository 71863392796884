import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { db, auth, storage } from '../firebaseConfig';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import AddTeamPopup from './AddTeamPopup';
import './Dashboard.css';

const Dashboard = () => {
  const [userData, setUserData] = useState({
    name: '',
    birthday: '',
    bio: '',
    profileImage: 'https://via.placeholder.com/150',
  });
  const [showImageEdit, setShowImageEdit] = useState(false);
  const [isTeamExists, setIsTeamExists] = useState(false);
  const [showAddTeamPopup, setShowAddTeamPopup] = useState(false);
  const [teamDetails, setTeamDetails] = useState(null);

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = () => {
      if (userId) {
        const userDoc = doc(db, 'users', userId);
        const unsubscribe = onSnapshot(userDoc, (doc) => {
          if (doc.exists()) {
            setUserData(doc.data());
          } else {
            console.log('No such document!');
          }
        });
        return () => unsubscribe();
      }
    };
    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchUserTeam = async () => {
      if (userId) {
        try {
          const loginDoc = doc(db, 'LoginDetails', userId);
          const loginSnapshot = await getDoc(loginDoc);

          if (loginSnapshot.exists()) {
            const loginData = loginSnapshot.data();
            if (loginData.InTeam) {
              const teamDoc = doc(db, 'teams', loginData.TeamID);
              const teamSnapshot = await getDoc(teamDoc);

              if (teamSnapshot.exists()) {
                setIsTeamExists(true);
                setTeamDetails(teamSnapshot.data());
                console.log('Team details: ', teamSnapshot.data());
              } else {
                setIsTeamExists(false);
              }
            } else {
              setIsTeamExists(false);
            }
          }
        } catch (error) {
          console.error('Error fetching team: ', error);
        }
      }
    };
    fetchUserTeam();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const oldProfileImageRef = ref(storage, `profileImages/${userId}`);
        await deleteObject(oldProfileImageRef).catch((error) => {
          console.log('No old image to delete or error:', error);
        });

        const newProfileImageRef = ref(storage, `profileImages/${userId}`);
        await uploadBytes(newProfileImageRef, file);

        const downloadURL = await getDownloadURL(newProfileImageRef);

        const userDoc = doc(db, 'users', userId);
        await setDoc(userDoc, {
          ...userData,
          profileImage: downloadURL,
        });
        setUserData({ ...userData, profileImage: downloadURL });
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDoc = doc(db, 'users', userId);
      await setDoc(userDoc, {
        name: userData.name,
        birthday: userData.birthday,
        bio: userData.bio,
        profileImage: userData.profileImage,
      });
      alert('User data saved successfully!');
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="profile-section">
        <div
          className="profile-image-container"
          onMouseEnter={() => setShowImageEdit(true)}
          onMouseLeave={() => setShowImageEdit(false)}
        >
          <img src={userData.profileImage} alt="Profile" className="profile-image" />
          {showImageEdit && (
            <div className="overlay">
              <label htmlFor="imageUpload" className="image-edit-icon">
                <MdEdit size={30} />
              </label>
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
            </div>
          )}
        </div>
        <div className="profile-details">
          <p>{userData.name || 'Enter your name'}</p>
          <p>{userData.birthday || 'Enter your birthday'}</p>
          <p>{userData.bio || 'Tell us more about yourself :)'}</p>
        </div>
      </div>

      <div className="form-section">
        <form className="user-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={userData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Birthday</label>
            <input
              type="date"
              name="birthday"
              value={userData.birthday}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Bio</label>
            <input
              type="text"
              name="bio"
              placeholder="Enter a short bio"
              value={userData.bio}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="submit-btn-dash">Submit</button>
        </form>

        {isTeamExists && teamDetails ? (
          <div className="dash-team-card">
            <div className="team-logo-dash">
              <img 
                src={teamDetails.teamLogo || 'https://via.placeholder.com/150'} 
                alt={`${teamDetails.teamName} logo`} 
              />
            </div>
            <div className="team-info">
              <h3>{teamDetails.teamName}</h3>
              <p>Average Rank: {teamDetails.avgRank || 'N/A'}</p>
              <p>Match Wins: {teamDetails.matchWins || 0}</p>
              <p>Tournament Wins: {teamDetails.tournamentWins || 0}</p>
            </div>
            <div className="team-players">
              {teamDetails.players?.slice(0, 5).map((player, index) => (
                <a
                  key={index}
                  href={player.trackerLink || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {player.riotId ? player.riotId.split('#')[0] : 'Unknown Player'}
                </a>
              ))}
            </div>
          </div>
        ) : (
          <div className="team-button">
            <button
              className="add-team-btn"
              onClick={() => setShowAddTeamPopup(true)}
            >
              Add Your Team
            </button>
          </div>
        )}
      </div>

      {showAddTeamPopup && (
        <AddTeamPopup
          onClose={() => setShowAddTeamPopup(false)}
          onTeamCreated={() => {
            setIsTeamExists(true);
            setShowAddTeamPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;
