import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebaseConfig'; // Assuming you have set up Firebase config with Firestore and Storage
import { doc, onSnapshot } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import MapPool from './MapPool';
import TeamBannerAtk from './TeamBannerAtk';
import TeamBannerDef from './TeamBannerDef';
import PlayerBannerAtk from './PlayerBannerAtk';
import PlayerBannerDef from './PlayerBannerDef';
import './Overlay.css'; // Import CSS for OverlayDisplay

function OverlayDisplay() {
  const [attackTeam, setAttackTeam] = useState({});
  const [defendTeam, setDefendTeam] = useState({});
  const [maps, setMaps] = useState([{ status: '', name: '' }, { status: '', name: '' }, { status: '', name: '' }]);
  const [imageUrls, setImageUrls] = useState({
    attackPlayerImages: [],
    defendPlayerImages: [],
  });

  useEffect(() => {
    const unsubscribeAttack = onSnapshot(doc(db, "overlay", "attack"), (docSnapshot) => {
      if (docSnapshot.exists()) {
        setAttackTeam(docSnapshot.data());
      }
    });

    const unsubscribeDefend = onSnapshot(doc(db, "overlay", "defend"), (docSnapshot) => {
      if (docSnapshot.exists()) {
        setDefendTeam(docSnapshot.data());
      }
    });

    const unsubscribeMaps = onSnapshot(doc(db, "overlay", "maps"), (docSnapshot) => {
      if (docSnapshot.exists()) {
        setMaps([docSnapshot.data()]);
      }
    });

    return () => {
      unsubscribeAttack();
      unsubscribeDefend();
      unsubscribeMaps();
    };
  }, []);

  const getAgentImage = async (agent) => {
    const imageRef = ref(storage, `overlay-images/agents/${agent}.webp`);
    try {
      const url = await getDownloadURL(imageRef);
      return url;
    } catch (error) {
      console.error("Error fetching agent image:", error);
      return ''; // Return an empty string or a default image if it fails
    }
  };

  const getAgentRoleImage = async (role) => {
    const roleRef = ref(storage, `overlay-images/roles/${role}.webp`);
    try {
      const url = await getDownloadURL(roleRef);
      return url;
    } catch (error) {
      console.error("Error fetching role image:", error);
      return ''; // Return an empty string or a default image if it fails
    }
  };

  const getPlayerRole = (agent) => {
    const agentRoles = {
      Controller: ["Astra", "Brimstone", "Omen", "Viper", "Harbor", "Clove"],
      Duelist: ["Jett", "Phoenix", "Reyna", "Raze", "Neon", "Yoru", "Iso"],
      Initiator: ["Breach", "Sova", "Fade", "KAYO", "Skye", "Gekko"],
      Sentinel: ["Chamber", "Cypher", "Killjoy", "Sage", "Deadlock", "Vyse"]
    };

    for (let role in agentRoles) {
      if (agentRoles[role].includes(agent)) {
        return role;
      }
    }
    return 'Unknown'; // Fallback if no role is matched
  };

  // Fetch all images asynchronously but don't block rendering
  const loadPlayerImages = async (team) => {
    const playerImages = [];
    for (const player of team.players) {
      const agentRole = getPlayerRole(player.agent);
      const agentImageUrl = await getAgentImage(player.agent);
      const agentRoleImageUrl = await getAgentRoleImage(agentRole);

      playerImages.push({ agentImageUrl, agentRoleImageUrl });
    }

    return playerImages;
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (attackTeam.players) {
          const attackPlayerImages = await loadPlayerImages(attackTeam);
          setImageUrls(prevState => ({
            ...prevState,
            attackPlayerImages
          }));
        }

        if (defendTeam.players) {
          const defendPlayerImages = await loadPlayerImages(defendTeam);
          setImageUrls(prevState => ({
            ...prevState,
            defendPlayerImages
          }));
        }
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    fetchImages();
  }, [attackTeam, defendTeam]);

  const renderPlayerBanners = (team, side) => {
    const playerImages = side === 'attack' ? imageUrls.attackPlayerImages : imageUrls.defendPlayerImages;
    return team.players.slice(0, 5).map((player, index) => {
      const agentImageUrl = playerImages[index]?.agentImageUrl || '';
      const agentRoleImageUrl = playerImages[index]?.agentRoleImageUrl || '';
  
      return side === 'attack' ? (
        <PlayerBannerAtk
          key={index}
          playerName={player.name}
          playerImageUrl={agentImageUrl}
          kda={`${player.KDA.kills}/${player.KDA.deaths}/${player.KDA.assists}`}
          agentImageUrl={agentRoleImageUrl}
          isDead={player.isdead} // Pass the isdead status as isDead prop
          imageUrl="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/overlay-images%2Fplayer_atk.png?alt=media&token=892e5755-31ba-4bb5-ae05-15d581aa34df"
        />
      ) : (
        <PlayerBannerDef
          key={index}
          playerName={player.name}
          playerImageUrl={agentImageUrl}
          kda={`${player.KDA.kills}/${player.KDA.deaths}/${player.KDA.assists}`}
          agentImageUrl={agentRoleImageUrl}
          isDead={player.isdead} // Pass the isdead status as isDead prop
          imageUrl="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/overlay-images%2Fplayer_def.png?alt=media&token=d340860b-c629-45d5-b5ba-b36d6f1be423"
        />
      );
    });
  };
  

  return (
    <div className="overlay-display">
      <MapPool status={maps[0].status} mapName={maps[0].name} imageUrl="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/overlay-images%2Fmap_pool.png?alt=media&token=20b21bbc-5425-4127-8881-7059c5755625" />

      <TeamBannerAtk 
        teamLogoUrl={attackTeam.teamLogo || "default_logo_url"}
        teamName={attackTeam.teamName || "Team Attack"} 
        roundCount={attackTeam.rounds || "0"} 
        imageUrl="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/overlay-images%2Fteam_atk.png?alt=media&token=b5580570-0263-40dc-905f-185e6c38528a" 
      />
      <TeamBannerDef 
        teamLogoUrl={defendTeam.teamLogo || "default_logo_url"}
        teamName={defendTeam.teamName || "Team Defend"} 
        roundCount={defendTeam.rounds || "0"} 
        imageUrl="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/overlay-images%2Fteam_def.png?alt=media&token=18b2de7b-d9ea-4c7d-9d8d-15760a885243" 
      />

      <div className="player-banner-attack-wrapper">
        {attackTeam.players && renderPlayerBanners(attackTeam, 'attack')}
      </div>

      <div className="player-banner-defend-wrapper">
        {defendTeam.players && renderPlayerBanners(defendTeam, 'defend')}
      </div>
    </div>
  );
}

export default OverlayDisplay;
