// Import the necessary functions from Firebase SDKs
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Firebase configuration for your web app
const firebaseConfig = {
  apiKey: "AIzaSyCYSAgdIYYUq4T5_RSPvvdCyxqBsjCcojg",
  authDomain: "team-registations.firebaseapp.com",
  projectId: "team-registations",
  storageBucket: "team-registations.appspot.com",
  messagingSenderId: "778520272972",
  appId: "1:778520272972:web:5de6d86ac4beaf08f9d4e9",
  measurementId: "G-EVPGBYTQY8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);        // Firestore database
const auth = getAuth(app);           // Firebase Authentication
const storage = getStorage(app);     // Firebase Storage
const analytics = getAnalytics(app); // Firebase Analytics (optional)

// Initialize Google Auth provider
const googleProvider = new GoogleAuthProvider();

// Export Firebase services
export { auth, storage, googleProvider, db, analytics, ref, getDownloadURL };
