import React, { useState } from 'react';
import './LoginPopup.css';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon
import { auth, googleProvider } from '../firebaseConfig.js';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { getFirestore, setDoc, doc, getDoc, collection, getDocs } from 'firebase/firestore';

const db = getFirestore();

function LoginPopup({ onClose }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const toggleForm = () => setIsSignUp(!isSignUp);

  const saveLoginDetails = async (user) => {
    const userDocRef = doc(db, 'LoginDetails', user.uid);

    try {
      let isInTeam = false;
      let teamID = null;

      const teamDocRef = doc(db, 'teams', user.uid);
      const teamDocSnapshot = await getDoc(teamDocRef);

      if (teamDocSnapshot.exists()) {
        isInTeam = true;
        teamID = user.uid;
      } else {
        const teamsCollectionRef = collection(db, 'teams');
        const querySnapshot = await getDocs(teamsCollectionRef);

        querySnapshot.forEach((teamDoc) => {
          const playersArray = teamDoc.data().players || [];

          playersArray.forEach((player) => {
            if (player.email === user.email) {
              isInTeam = true;
              teamID = teamDoc.id;
            }
          });
        });
      }

      await setDoc(
        userDocRef,
        {
          UID: user.uid,
          Email: user.email,
          InTeam: isInTeam,
          TeamID: teamID,
        },
        { merge: true }
      );
    } catch (error) {
      console.error('Error checking team membership: ', error);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user, {
        url: 'http://lucifersl.com/', // Replace with your custom verification page URL
        handleCodeInApp: true,
      });
      await saveLoginDetails(user);

      await auth.signOut();
      setSuccessMessage(
        'Sign up successful! Please verify your email to log in. Check your inbox for a verification link.'
      );
      setError('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError(error.message || 'Failed to sign up. Please try again.');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        await saveLoginDetails(user);
        setSuccessMessage('Login successful! Redirecting...');
        setError('');
        onClose();
      } else {
        setError('Please verify your email before logging in.');
        await auth.signOut();
      }
    } catch (error) {
      setError('Failed to login. Check your credentials and try again.');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      if (user.emailVerified) {
        await saveLoginDetails(user);
        setSuccessMessage('Login successful with Google! Redirecting...');
        setError('');
        onClose();
      } else {
        setError('Please verify your email before logging in.');
      }
    } catch (error) {
      setError('Failed to login with Google. Please try again.');
    }
  };

  return (
    <div className="login-popup-overlay">
      <div className="login-popup-content">
        {!successMessage && (
          <button className="login-close-btn" onClick={onClose}>
            X
          </button>
        )}

        {successMessage ? (
          <div className="login-success-message">
            <h2>{successMessage}</h2>
            <p>Please check your inbox and follow the verification link. You can close this tab now.</p>
          </div>
        ) : (
          <>
            <h2 className="login-title">{isSignUp ? 'Sign Up' : 'Login'}</h2>
            {error && <p className="login-error-message">{error}</p>}

            <button className="login-google-btn" onClick={handleGoogleLogin}>
              <FcGoogle size={24} className="google-icon" /> Quick Login with Google
            </button>

            <hr className="login-divider" />

            <form onSubmit={isSignUp ? handleSignUp : handleLogin}>
              <div className="login-input-group">
                <label className="login-label">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login-input"
                  required
                />
              </div>
              <div className="login-input-group">
                <label className="login-label">Password</label>
                <input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="login-input"
                  required
                />
              </div>

              {isSignUp && (
                <div className="login-input-group">
                  <label className="login-label">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="login-input"
                    required
                  />
                </div>
              )}

              <button type="submit" className="login-submit-btn">
                {isSignUp ? 'Sign Up' : 'Login'}
              </button>
            </form>

            <p className="login-toggle-text">
              {isSignUp ? 'Already have an account?' : 'New user?'}{' '}
              <span className="login-toggle-btn" onClick={toggleForm}>
                {isSignUp ? 'Login' : 'Sign Up'}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginPopup;
