// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Tournaments from './pages/Tournaments';
import Teams from './pages/Teams';
import News from './pages/News';
import ArticlePage from './pages/ArticlePage';
import AboutUs from './pages/About';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import AdminPanel from './pages/AdminPanel';
import TournamentArticle from './pages/TournamentArticle';
import VerifyEmail from './pages/VerifyEmail';
import ProtectedRoute from './pages/ProtectedRoute';
import OverlayAdmin from './pages/OverlayControlPanel';
import Overlay from './pages/OverlayDisplay';
import Layout from './Layout'; // Import Layout component
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, getFirestore } from 'firebase/firestore';

const db = getFirestore();

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch user details on auth state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const userData = userDoc.exists() ? userDoc.data() : {};

        setUser({
          ...currentUser,
          isAdmin: userData.isAdmin || false,
        });
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<ArticlePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/tournament/:id" element={<TournamentArticle />} />

          {/* Protected routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute user={user}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute user={user} isAdminRoute={true}>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/overlay-admin"
            element={
              <ProtectedRoute user={user} isAdminRoute={true}>
                <OverlayAdmin />
              </ProtectedRoute>
            }
          />
          <Route path="/overlay" element={<Overlay />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
