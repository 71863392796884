import React from 'react';
import './PlayerBannerDef.css'; // Import the CSS file for styling

function PlayerBannerDef({ playerName, playerImageUrl, kda, agentImageUrl, imageUrl, isDead }) {
  return (
    <div 
      className="player-banner-def" 
      style={{
        width: '314px',
        height: '93px',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        marginBottom: '10px',
        filter: isDead ? 'grayscale(1)' : 'none', // Apply greyscale if isDead is true
      }}
    >
      <div className="player-content-wrapper-def">
        {/* Agent Image */}
        <div className="agent-image-def">
          <img src={agentImageUrl} alt="Agent Type" />
        </div>

        {/* Player Info (Name and KDA) */}
        <div className="player-info-def">
          <span className="player-name-def">{playerName}</span>
          <span className="player-kda">{kda}</span>
        </div>

        {/* Player Image */}
        <div className="player-image-def">
          <img src={playerImageUrl} alt={`${playerName} Image`} />
        </div>
      </div>
    </div>
  );
}

export default PlayerBannerDef;
