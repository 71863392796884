import React, { useState } from 'react';
import { db, storage } from '../firebaseConfig'; // Import Firestore and Storage
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import './AddNewsPopup.css';

function AddNewsPopup({ onClose }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [publishedDate, setPublishedDate] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null); // For image upload
  const [loading, setLoading] = useState(false); // For loading state

  const handleImageUpload = async (file) => {
    if (!file) return '';

    const storageRef = ref(storage, `news-images/${file.name}`); // Set storage path
    await uploadBytes(storageRef, file); // Upload the image
    const downloadURL = await getDownloadURL(storageRef); // Get the image URL
    return downloadURL; // Return the URL
  };

  const handleAddNews = async (e) => {
    e.preventDefault();
    setLoading(true);

    let uploadedImageUrl = '';

    // Ensure image is uploaded before submitting
    if (image) {
      uploadedImageUrl = await handleImageUpload(image);
    }

    // Add news data to Firestore
    await addDoc(collection(db, 'News'), {
      title,
      description,
      category,
      author,
      publishedDate,
      content,
      imageUrl: uploadedImageUrl, // Save the image URL
    });

    // Reset form
    setTitle('');
    setDescription('');
    setCategory('');
    setAuthor('');
    setPublishedDate('');
    setContent('');
    setImage(null);
    setLoading(false);
    onClose(); // Close the popup after submission
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className="popup-title">Add News</h2>
        <form className="popup-form" onSubmit={handleAddNews}>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <label>Description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />

          <label>Category</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />

          <label>Author</label>
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />

          <label>Published Date</label>
          <input
            type="date"
            value={publishedDate}
            onChange={(e) => setPublishedDate(e.target.value)}
            required
          />

          <label>Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />

          <label>Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />

          {loading ? (
            <p>Uploading...</p>
          ) : (
            <div className="popup-buttons">
              <button type="submit" disabled={loading}>
                Submit
              </button>
              <button type="button" onClick={onClose}>
                Close
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddNewsPopup;
