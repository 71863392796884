import React, { useEffect, useState } from 'react';
import { FaDiscord, FaYoutube, FaTwitch, FaFacebook, FaTiktok } from 'react-icons/fa'; // Import icons from react-icons
import { db } from '../firebaseConfig'; // Import Firestore config
import { collection, getDocs } from 'firebase/firestore'; // Firestore methods
import { getAuth } from 'firebase/auth'; // Import Firebase Auth
import './HomePage.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();
    const [latestVideo, setLatestVideo] = useState(null);
    const [topTeams, setTopTeams] = useState([]);

    const auth = getAuth(); // Initialize Firebase Auth

    // Fetch the latest video from your YouTube channel using the YouTube API
    const fetchLatestVideo = async () => {
        try {
            const API_KEY = 'AIzaSyCBDPN0-S1CUGcbj1qWUPpKxjIwcSuM37w'; // Replace with your YouTube API key
            const CHANNEL_ID = 'UC0gGHayFjUdqB0jMYK_RGgw'; // Replace with your channel ID
            const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&order=date&part=snippet&type=video`);
            const data = await response.json();

            // Check if data.items exists and has at least one item
            if (data.items && data.items.length > 0) {
                setLatestVideo(data.items[0]);
            } else {
                console.error('No videos found for the channel.');
            }
        } catch (error) {
            console.error('Error fetching the latest video:', error);
        }
    };

    // Fetch top 3 teams from Firestore sorted by tournamentWins
    const fetchTopTeams = async () => {
        try {
            const teamsCollection = collection(db, 'teams');
            const teamSnapshot = await getDocs(teamsCollection);
            const teamsList = teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Sort teams by tournamentWins in descending order
            const sortedTeams = teamsList.sort((a, b) => (b.tournamentWins || 0) - (a.tournamentWins || 0));
            
            // Select the top 3 teams
            setTopTeams(sortedTeams.slice(0, 3));
        } catch (error) {
            console.error('Error fetching top teams:', error);
        }
    };

    useEffect(() => {
        fetchLatestVideo();
        fetchTopTeams();
    }, []);

    // Function to handle team registration button click
    const handleRegisterTeam = () => {
        if (!auth.currentUser) {
            // Show login popup if the user is not signed in
            alert("Please log in to register your team!");
            // Here you can trigger your login popup if you have one
        } else {
            // Redirect to the dashboard if the user is signed in
            navigate('/dashboard');
        }
    };

    return (
        <div className="homepage">
            {/* Hero Section */}
            <section className="hero">
                <video 
                    autoPlay 
                    muted 
                    loop 
                    playsInline 
                    className="hero-video" 
                    src="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2Ffond-decran-cod-bo6-call-of-duty-black-ops-6-hd-animated-wallpaper.mp4?alt=media&token=fb35ecf2-989b-409b-a6b8-68fe24341a0a">
                    Your browser does not support the video tag.
                </video>
                <div className="hero-content">
                    <h1 className="hero-title">Welcome to <span className="cordovan">Team Lucifer</span></h1>
                    <p className="hero-description">Join us as we conquer the gaming world together!</p>
                </div>
            </section>

            {/* Tournaments Section */}
            <section className="tournaments">
                <div className="tournament-content">
                    <h2 className="tournaments-title">Tournaments</h2>
                    <p className="tournaments-description">Check out the exciting tournaments we host!</p>
                    <div className="tournament-buttons">
                        <button 
                            className="tournament-btn" 
                            onClick={() => navigate('/tournaments')} // Link to tournaments page
                        >
                            View Tournaments
                        </button>
                        <button 
                            className="tournament-btn" 
                            onClick={handleRegisterTeam} // Register your team button
                        >
                            Register Your Team Now!
                        </button>
                    </div>
                </div>
            </section>

            {/* Leaderboard Section */}
            <section className="leaderboard">
                <h2 className="leaderboard-title">Our Leaderboard</h2>
                <div className="leaderboard-cards">
                    {topTeams[0] && (
                        <div className="card gold">
                            <h3>1st</h3>
                            <img src={topTeams[0].teamLogo || 'https://via.placeholder.com/150'} alt={`${topTeams[0].teamName} logo`} />
                            <h4>{topTeams[0].teamName}</h4>
                            <p>Avg Rank: {topTeams[0].avgRank || 'N/A'}</p>
                            <p>Tournament Wins: {topTeams[0].tournamentWins || 0}</p>
                        </div>
                    )}
                    {topTeams[1] && (
                        <div className="card silver">
                            <h3>2nd</h3>
                            <img src={topTeams[1].teamLogo || 'https://via.placeholder.com/150'} alt={`${topTeams[1].teamName} logo`} />
                            <h4>{topTeams[1].teamName}</h4>
                            <p>Avg Rank: {topTeams[1].avgRank || 'N/A'}</p>
                            <p>Tournament Wins: {topTeams[1].tournamentWins || 0}</p>
                        </div>
                    )}
                    {topTeams[2] && (
                        <div className="card bronze">
                            <h3>3rd</h3>
                            <img src={topTeams[2].teamLogo || 'https://via.placeholder.com/150'} alt={`${topTeams[2].teamName} logo`} />
                            <h4>{topTeams[2].teamName}</h4>
                            <p>Avg Rank: {topTeams[2].avgRank || 'N/A'}</p>
                            <p>Tournament Wins: {topTeams[2].tournamentWins || 0}</p>
                        </div>
                    )}
                </div>
            </section>

            {/* Latest YouTube Video Section */}
            <section className="latest-video">
                <div className="video-title-container">
                    <h2 className="video-title">Watch Our Latest Video</h2>
                </div>
                {latestVideo && (
                    <div className="video-container">
                        <iframe
                            title="Latest YouTube Video"
                            src={`https://www.youtube.com/embed/${latestVideo.id.videoId}`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                )}
            </section>

            {/* Social Media Links Section */}
            <section className="social-media">
                <div className="social-media-content">
                    <div className="social-left">
                        <h2 className="social-title">Follow Us</h2>
                        <p className="social-description">Stay connected with our gaming community! Join our Discord group 'Monkey Kingdom' for exclusive updates and discussions.</p>
                        <a href="https://discord.gg/gDWZ3ruR" className="social-btn discord" target="_blank" rel="noreferrer"><FaDiscord />Join Discord</a>
                    </div>
                    <div className="social-right">
                        <a href="https://www.youtube.com/@SL_Lucif3R" className="social-btn youtube" target="_blank" rel="noreferrer"><FaYoutube /> YouTube</a>
                        <a href="https://www.twitch.tv/sl_lucif3r" className="social-btn twitch" target="_blank" rel="noreferrer"><FaTwitch /> Twitch</a>
                        <a href="https://web.facebook.com/watch/SLLucif3R/" className="social-btn facebook" target="_blank" rel="noreferrer"><FaFacebook /> Facebook</a>
                        <a href="https://www.tiktok.com/@sllucif3r" className="social-btn tiktok" target="_blank" rel="noreferrer"><FaTiktok /> TikTok</a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
