import React, { useState } from 'react';
import { db, storage } from '../firebaseConfig'; // Firebase config
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import './AddTournamentPopup.css';

function AddTournamentPopup({ onClose, onAdd }) {
  const [tournamentDetails, setTournamentDetails] = useState({
    TournamentTitle: '',
    Organiser: '',
    Game: 'Valorant', // Default value
    EventStartDate: '',
    EventStartTime: '',
    EventEndDate: '',
    RegistrationClosingDate: '',
    PrizePool: '',
    Status: 'upcoming', // Default value
    TeamLimit: '',
    TournamentFormat: 'single elimination', // Default value
    BannerImage: '',
    Description: '',
  });

  const [imageFile, setImageFile] = useState(null); // State to hold the image file

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTournamentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Save the file for later upload
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let imageUrl = '';
    if (imageFile) {
      // Step 1: Upload the image to Firebase Storage
      const imageRef = ref(storage, `tournament_banners/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);

      // Step 2: Get the download URL for the uploaded image
      imageUrl = await getDownloadURL(imageRef);
    }

    try {
      // Step 3: Add the tournament data along with the image URL to Firestore
      await addDoc(collection(db, 'Tournaments'), {
        ...tournamentDetails,
        BannerImage: imageUrl, // Store the image URL
        registeredTeams: [],
        registeredTeamsCount: 0,
      });
      alert('Tournament created successfully!');
      onAdd(); // Refresh tournaments list
      onClose(); // Close popup
      setTournamentDetails({}); // Reset form
    } catch (error) {
      console.error('Error adding tournament: ', error);
      alert('Error creating tournament!');
    }
  };

  return (
    <div className="tournament-popup-overlay">
      <div className="tournament-popup-content">
        <h2 className="tournament-popup-title">Create New Tournament</h2>
        <form onSubmit={handleSubmit} className="tournament-popup-form">
          <div className="tournament-popup-field">
            <label>
              Tournament Title
              <input
                name="TournamentTitle"
                placeholder="Tournament Title"
                value={tournamentDetails.TournamentTitle}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Organiser
              <input
                name="Organiser"
                placeholder="Organiser"
                value={tournamentDetails.Organiser}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Game
              <select
                name="Game"
                value={tournamentDetails.Game}
                onChange={handleInputChange}
                required
              >
                <option value="Valorant">Valorant</option>
                <option value="CS:GO">CS:GO</option>
                <option value="Dota 2">Dota 2</option>
                <option value="League of Legends">League of Legends</option>
              </select>
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Start Date
              <input
                name="EventStartDate"
                type="date"
                value={tournamentDetails.EventStartDate}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Start Time
              <input
                name="EventStartTime"
                type="time"
                value={tournamentDetails.EventStartTime}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              End Date
              <input
                name="EventEndDate"
                type="date"
                value={tournamentDetails.EventEndDate}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Registration Closing Date
              <input
                name="RegistrationClosingDate"
                type="date"
                value={tournamentDetails.RegistrationClosingDate}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Prize Pool
              <input
                name="PrizePool"
                placeholder="Prize Pool"
                value={tournamentDetails.PrizePool}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Status
              <select
                name="Status"
                value={tournamentDetails.Status}
                onChange={handleInputChange}
                required
              >
                <option value="upcoming">Upcoming</option>
                <option value="ongoing">Ongoing</option>
                <option value="completed">Completed</option>
              </select>
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Team Limit
              <input
                name="TeamLimit"
                placeholder="Team Limit"
                type="number"
                value={tournamentDetails.TeamLimit}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Tournament Format
              <select
                name="TournamentFormat"
                value={tournamentDetails.TournamentFormat}
                onChange={handleInputChange}
                required
              >
                <option value="single elimination">Single Elimination</option>
                <option value="double elimination">Double Elimination</option>
                <option value="round robin">Round Robin</option>
              </select>
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Banner Image
              <input type="file" accept="image/*" onChange={handleFileChange} required />
              {imageFile && <img src={URL.createObjectURL(imageFile)} alt="Banner preview" />} {/* Preview the uploaded image */}
            </label>
          </div>
          <div className="tournament-popup-field">
            <label>
              Description
              <textarea
                name="Description"
                placeholder="Description"
                value={tournamentDetails.Description}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <div className="tournament-popup-footer">
            <button type="submit">Create Tournament</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddTournamentPopup;
