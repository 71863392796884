import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import './News.css'; // Import the CSS for styling

const News = () => {
    const [articles, setArticles] = useState([]);

    // Fetch all articles from Firestore
    const fetchArticles = async () => {
        try {
            const articlesCollection = collection(db, 'News'); // Firestore 'news' collection
            const articlesSnapshot = await getDocs(articlesCollection);
            const articlesList = articlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setArticles(articlesList);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    // Function to truncate the content
    const truncateContent = (content, maxLength = 150) => {
        if (content.length > maxLength) {
            return content.substring(0, maxLength) + '...';
        }
        return content;
    };

    return (
        <div className="news-container">
            <h1 className="news-title">Latest News</h1>
            <div className="news-grid">
                {articles.map((article) => (
                    <div key={article.id} className="news-card">
                        <img src={article.imageUrl} alt={article.title} className="news-banner" />
                        <h2 className="news-article-title">{article.title}</h2>
                        <p className="news-article-author">By {article.author}</p>
                        <p className="news-article-date">{new Date(article.publishedDate).toLocaleDateString()}</p>
                        <div className="news-tags">
                            {article.tags && article.tags.map((tag, index) => (
                                <span key={index} className="news-tag">{tag}</span>
                            ))}
                        </div>
                        <p className="news-article-description">
                            {truncateContent(article.content)}
                        </p>
                        <Link to={`/news/${article.id}`} className="read-more-btn">Read More</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default News;
