import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './TournamentArticle.css'; // Import the CSS file

const TournamentArticle = () => {
  const { id } = useParams(); // Get the tournament ID from the URL
  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    const fetchTournament = async () => {
      const tournamentRef = doc(db, 'Tournaments', id); // Fetch specific tournament by ID
      const tournamentSnapshot = await getDoc(tournamentRef);

      if (tournamentSnapshot.exists()) {
        setTournament(tournamentSnapshot.data()); // Set the tournament data to state
      }
    };

    fetchTournament();
  }, [id]);

  if (!tournament) {
    return <p>Loading tournament details...</p>;
  }

  return (
    <div className="tournament-article">
      <div className="banner-container">
        <img src={tournament.BannerImage} alt={tournament.TournamentTitle} className="banner-image" />
      </div>

      

      <div className="content-container">
        <div className="description-container">
        <h1 className="tournament-title">{tournament.TournamentTitle}</h1>
          <h2>About the Tournament</h2>
          <p>{tournament.Description}</p> {/* Display the full tournament description */}
        </div>

        <div className="details-container">
          <h3>Tournament Details</h3>
          <table className="tournament-details-table">
            <tbody>
              <tr>
                <td><strong>Game:</strong></td>
                <td>{tournament.Game}</td>
              </tr>
              <tr>
                <td><strong>Organizer:</strong></td>
                <td>{tournament.Organiser}</td>
              </tr>
              <tr>
                <td><strong>Prize Pool:</strong></td>
                <td>{tournament.PrizePool}</td>
              </tr>
              <tr>
                <td><strong>Status:</strong></td>
                <td>{tournament.Status}</td>
              </tr>
              <tr>
                <td><strong>Team Limit:</strong></td>
                <td>{tournament.TeamLimit}</td>
              </tr>
              <tr>
                <td><strong>Tournament Format:</strong></td>
                <td>{tournament.TournamentFormat}</td>
              </tr>
              <tr>
                <td><strong>Start Date:</strong></td>
                <td>{tournament.EventStartDate}</td>
              </tr>
              <tr>
                <td><strong>Start Time:</strong></td>
                <td>{tournament.EventStartTime}</td>
              </tr>
              <tr>
                <td><strong>End Date:</strong></td>
                <td>{tournament.EventEndDate}</td>
              </tr>
              <tr>
                <td><strong>Registration Closing Date:</strong></td>
                <td>{tournament.RegistrationClosingDate}</td>
              </tr>
              <tr>
                <td><strong>Registered Teams:</strong></td>
                <td>{tournament.registeredTeamsCount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TournamentArticle;
