import React from 'react';
import './Footer.css';  // Make sure to update your CSS accordingly
import { FaDiscord, FaYoutube, FaTiktok, FaTwitch, FaFacebook, FaWhatsapp } from 'react-icons/fa';  // Social media icons

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left-section">
          <p>Follow us:</p>
          <div className="social-icons">
            <a href="https://discord.gg/gDWZ3ruR" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </a>
            <a href="https://www.youtube.com/@SL_Lucif3R" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="https://www.tiktok.com/@sllucif3r" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.twitch.tv/sl_lucif3r" target="_blank" rel="noopener noreferrer">
              <FaTwitch />
            </a>
            <a href="https://web.facebook.com/watch/SLLucif3R/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://chat.whatsapp.com/Ck2G1UgWJ7v1G7c827btwC" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </div>
        </div>

        <div className="center-section">
          <p>© 2024 Team Lucifer. All rights reserved.</p>
        </div>

        <div className="right-section">
          <p>Solo project by: Moshi@TeamLucifer</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
