import React from 'react';
import './PlayerBannerAtk.css';

function PlayerBannerAtk({ playerName, playerImageUrl, kda, agentImageUrl, imageUrl, isDead }) {
  return (
    <div 
      className="player-banner-atk" 
      style={{
        width: '314px',
        height: '93px',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        marginBottom: '10px',
        filter: isDead ? 'grayscale(1)' : 'none', // Apply grayscale filter if isDead is true
      }}
    >
      <div className="player-content-wrapper-atk">
        {/* Player Image */}
        <div className="player-image-atk">
          <img src={playerImageUrl} alt={`${playerName} Image`} />
        </div>

        {/* Player Info (Name and KDA) */}
        <div className="player-info-atk">
          <span className="player-name">{playerName}</span>
          <span className="player-kda">{kda}</span>
        </div>

        {/* Agent Image */}
        <div className="agent-image-atk">
          <img src={agentImageUrl} alt="Agent Role" />
        </div>
      </div>
    </div>
  );
}

export default PlayerBannerAtk;
