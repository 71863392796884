// MapPool.js
import React, { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import './MapPool.css'; // Import the CSS file

function MapPool({ imageUrl }) {
  const [mapData, setMapData] = useState({
    map1status: 'Loading...',
    map1name: 'Loading...',
    map2status: 'Loading...',
    map2name: 'Loading...',
    map3status: 'Loading...',
    map3name: 'Loading...',
  });

  useEffect(() => {
    const db = getFirestore();
    const mapDocRef = doc(db, 'overlay', 'maps'); // Replace 'overlay' and 'maps' with your Firestore collection and document name

    // Real-time listener
    const unsubscribe = onSnapshot(mapDocRef, (snapshot) => {
      if (snapshot.exists()) {
        setMapData(snapshot.data());
      }
    });

    // Cleanup on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <div 
      className="map-pool" 
      style={{
        width: '332px',
        height: '39px',
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
      }}
    >
      <span className="map-box current-map">{`${mapData.map1status} - ${mapData.map1name}`}</span>
      <span className="map-box next-map">{`${mapData.map2status} - ${mapData.map2name}`}</span>
      <span className="map-box decider-map">{`${mapData.map3status} - ${mapData.map3name}`}</span>
    </div>
  );
}

export default MapPool;
