// src/components/TeamInfoPopup.js

import React from 'react';
import './TeamInfoPopup.css'; // Add a new CSS file for styling

const TeamInfoPopup = ({ team, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-team-info">
        <h2>{team.teamName}</h2>
        <img src={team.teamLogo} alt={team.teamName} className="team-info-logo" />
        
        <div className="team-players-info">
          {team.players.map((player, index) => (
            <div key={index} className="player-info">
              <h3>Player {index + 1}</h3>
              <p><strong>Riot ID:</strong> {player.riotId}</p>
              <p><strong>Rank:</strong> {player.rank}</p>
              <p><strong>Tracker Link:</strong> <a href={player.trackerLink} target="_blank" rel="noopener noreferrer">{player.trackerLink}</a></p>
              <p><strong>Email:</strong> {player.email}</p>
              <p><strong>Phone:</strong> {player.phone}</p>
            </div>
          ))}
        </div>
        
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default TeamInfoPopup;
