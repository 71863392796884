// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Make sure auth is correctly configured

const ProtectedRoute = ({ user, isAdminRoute, children }) => {
  // Check if the user is logged in
  if (!user) {
    return <Navigate to="/" replace />;
  }

  // Check if the route requires admin privileges and if the user is an admin
  if (isAdminRoute && !user.isAdmin) {
    return <Navigate to="/" replace />;
  }

  // Render the children (i.e., the protected content) if all checks pass
  return children;
};

export default ProtectedRoute;