import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './Navbar.css';
import LoginPopup from './LoginPopup';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';

function Navbar() {
  const [user, setUser] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && currentUser.emailVerified) {
        const userId = currentUser.uid;
        const userDoc = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userDoc);
        
        if (userSnapshot.exists()) {
          setUser({
            ...currentUser,
            ...userSnapshot.data(),
          });
        } else {
          setUser(currentUser);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error logging out: ', error);
      });
  };

  const openLoginPopup = (isSignUpMode = false) => {
    setIsSignUp(isSignUpMode);
    setShowLoginPopup(true);
  };

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img src="https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2Fweb_logo.png?alt=media&token=8682017e-952d-443e-a3ab-46da6042034f" alt="Logo" className="logo-image" />
      </Link>

      {/* Main Navbar Links */}
      <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        <li><Link to="/tournaments">Tournaments</Link></li>
        <li><Link to="/teams">Teams</Link></li>
        <li><Link to="/news">News</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
        {user && <li><Link to="/dashboard">Dashboard</Link></li>}
        {user?.isAdmin && <li><Link to="/admin">Admin Panel</Link></li>}
      </ul>

      {/* Slide-out Menu for Mobile */}
      <div className={`slide-menu ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/tournaments" onClick={toggleMenu}>Tournaments</Link>
        <Link to="/teams" onClick={toggleMenu}>Teams</Link>
        <Link to="/news" onClick={toggleMenu}>News</Link>
        <Link to="/about" onClick={toggleMenu}>About Us</Link>
        <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
        {user && <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>}
        {user?.isAdmin && <Link to="/admin" onClick={toggleMenu}>Admin Panel</Link>}
        
        <div className="auth-buttons">
          {user ? (
            <button className="logout-btn" onClick={handleLogout}>Logout</button>
          ) : (
            <>
              <button className="login-btn" onClick={() => { openLoginPopup(false); toggleMenu(); }}>Login</button>
              <button className="signup-btn" onClick={() => { openLoginPopup(true); toggleMenu(); }}>Sign Up</button>
            </>
          )}
        </div>
      </div>

      {/* Authentication and Profile Links */}
      <div className="navbar-auth">
        {user ? (
          <>
            <Link to="/dashboard" className="profile-link">
              {user.profileImage ? (
                <img
                  src={user.profileImage}
                  alt="Profile"
                  className="profile-icon"
                />
              ) : (
                <FaUserCircle className="profile-icon placeholder-icon" />
              )}
            </Link>
            <button className="logout-btn" onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <button className="login-btn" onClick={() => openLoginPopup(false)}>Login</button>
            <button className="signup-btn" onClick={() => openLoginPopup(true)}>Sign Up</button>
          </>
        )}
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </div>
      </div>

      {/* Login Popup */}
      {showLoginPopup && (
        <LoginPopup onClose={closeLoginPopup} isSignUp={isSignUp} />
      )}
    </nav>
  );
}

export default Navbar;
