import React, { useState, useEffect } from 'react';
import './Teams.css';
import { db } from '../firebaseConfig'; // Import Firestore config
import { collection, getDocs } from 'firebase/firestore'; // Firestore methods

function Teams() {
  const [teams, setTeams] = useState([]);

  // Fetch all teams from Firestore
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsCollection = collection(db, 'teams');
        const teamSnapshot = await getDocs(teamsCollection);
        const teamsList = teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTeams(teamsList);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchTeams();
  }, []);

  return (
    <div className="teams-grid">
      
      {teams.map(team => (
        <div className="team-card" key={team.id}>
          {/* Team logo */}
          <div className="team-logo-main">
            <img 
              src={team.teamLogo || 'https://via.placeholder.com/150'} 
              alt={`${team.teamName} logo`} 
            />
          </div>

          {/* Team details */}
          <div className="team-info">
            <h3>{team.teamName}</h3>
            <p>Average Rank: {team.avgRank || 'N/A'}</p>
            <p>Match Wins: {team.matchWins || 0}</p>
            <p>Tournament Wins: {team.tournamentWins || 0}</p>
          </div>

          {/* Team players */}
          <div className="team-players">
            {team.players?.slice(0, 5).map((player, index) => (
              <a
                key={index}
                href={player.trackerLink || '#'} // Use trackerLink for redirection
                target="_blank"
                rel="noopener noreferrer"
              >
                {player.riotId ? player.riotId.split('#')[0] : 'Unknown Player'}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Teams;
