// src/Layout.js

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function Layout({ children }) {
  const location = useLocation();

  // Utility function to check if the current route is an overlay route
  const isOverlayRoute = (path) => {
    return path === '/overlay-admin' || path === '/overlay';
  };

  useEffect(() => {
    if (isOverlayRoute(location.pathname)) {
      document.body.style.backgroundImage = 'none'; // Remove background image
      document.body.style.backgroundColor = 'transparent'; // Set transparent background
    } else {
      document.body.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/team-registations.appspot.com/o/Website_images%2FBG_IMG_PAGE.png?alt=media&token=56b3ab26-6a0f-40f7-83bf-21a01c2200ef')"; // Default background image
      document.body.style.backgroundColor = ''; // Reset to default color
    }
  }, [location.pathname]);

  return (
    <>
      {!isOverlayRoute(location.pathname) && <Navbar />}
      <main>{children}</main>
      {!isOverlayRoute(location.pathname) && <Footer />}
    </>
  );
}

export default Layout;
