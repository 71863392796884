// src/components/ManageTeamsPopup.js

import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './ManageTeamsPopup.css';
import TeamInfoPopup from './TeamInfoPopup';

const ManageTeamsPopup = ({ teams, onClose }) => {
  const [tempMatchWins, setTempMatchWins] = useState({});
  const [tempTournamentWins, setTempTournamentWins] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null); // State for the selected team info popup

  const handleMatchWinsChange = (teamId, increment) => {
    setTempMatchWins((prev) => ({
      ...prev,
      [teamId]: (prev[teamId] || 0) + increment,
    }));
  };

  const handleTournamentWinsChange = (teamId, increment) => {
    setTempTournamentWins((prev) => ({
      ...prev,
      [teamId]: (prev[teamId] || 0) + increment,
    }));
  };

  const handleSaveChanges = async () => {
    for (const teamId of teams.map((team) => team.id)) {
      const matchWins = (tempMatchWins[teamId] || 0) + teams.find((team) => team.id === teamId).matchWins;
      const tournamentWins = (tempTournamentWins[teamId] || 0) + teams.find((team) => team.id === teamId).tournamentWins;

      const teamRef = doc(db, 'teams', teamId);
      await updateDoc(teamRef, {
        matchWins,
        tournamentWins,
      });
    }

    setTempMatchWins({});
    setTempTournamentWins({});
    onClose();
  };

  // Open the team info popup for the selected team
  const handleViewInfo = (team) => {
    setSelectedTeam(team);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-manage-teams">
        <h2>Manage Teams</h2>
        <div className="team-edit-management">
          {teams.length > 0 ? (
            teams.map((team) => (
              <div key={team.id} className="edit-team-item">
                <img src={team.teamLogo} alt={team.teamName} className="team-edit-logo" />
                <span>{team.teamName}</span>
                
                <div className="wins-labels">
                  <label>Match Wins</label>
                </div>
                
                <div className="radio-button-group">
                  <button onClick={() => handleMatchWinsChange(team.id, -1)}>-</button>
                  <span>{(tempMatchWins[team.id] || 0) + team.matchWins}</span>
                  <button onClick={() => handleMatchWinsChange(team.id, 1)}>+</button>
                </div>
                
                <div className="wins-labels">
                  <label>Tournament Wins</label>
                </div>
                
                <div className="radio-button-group">
                  <button onClick={() => handleTournamentWinsChange(team.id, -1)}>-</button>
                  <span>{(tempTournamentWins[team.id] || 0) + team.tournamentWins}</span>
                  <button onClick={() => handleTournamentWinsChange(team.id, 1)}>+</button>
                </div>
                
                <button className="view-info-button" onClick={() => handleViewInfo(team)}>View Info</button>
              </div>
            ))
          ) : (
            <p>No teams available.</p>
          )}
        </div>
        
        <button className="save-changes-button" onClick={handleSaveChanges}>Save Changes</button>
        <button className="editteams-close-button" onClick={onClose}>Close</button>
        
        {selectedTeam && (
          <TeamInfoPopup team={selectedTeam} onClose={() => setSelectedTeam(null)} />
        )}
      </div>
    </div>
  );
};

export default ManageTeamsPopup;
