// src/pages/VerifyEmail.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

function VerifyEmail() {
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkVerification = async () => {
      // Check if a user is currently logged in
      if (auth.currentUser) {
        await auth.currentUser.reload(); // Reload the user to check their verification status
        if (auth.currentUser.emailVerified) {
          navigate('/'); // Redirect to homepage if verified
        } else {
          // Set a timer to redirect to homepage after 30 seconds
          const timer = setTimeout(() => {
            navigate('/'); // Redirect to homepage after timer expires
          }, 5000); // 30000 milliseconds = 30 seconds

          // Optional: Show a message about the timer
          console.log("Verification link clicked. You will be redirected in 30 seconds if not verified.");

          // Cleanup function to clear the timer if component unmounts
          return () => clearTimeout(timer);
        }
      } else {
        // Handle the case where no user is authenticated
        console.log('No user is currently logged in.');
        // Optionally redirect to the login page
        navigate('/');
      }
    };

    checkVerification();
  }, [auth, navigate]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>Please verify your email to continue. You will be redirected to the homepage in 30 seconds if not verified.</p>
    </div>
  );
}

export default VerifyEmail;
