import React from 'react';
import './Contact.css';
import { FaYoutube, FaTwitch, FaFacebookF, FaTiktok, FaDiscord, FaWhatsapp } from 'react-icons/fa';

const Contact = () => {
    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <p>We'd love to hear from you! Whether you have questions, feedback, or suggestions, feel free to reach out to us.</p>
            
            <div className="contact-section">
                <div className="contact-form">
                    <h2>Send Us a Message</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Your Name</label>
                            <input type="text" id="name" placeholder="Enter your name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Your Email</label>
                            <input type="email" id="email" placeholder="Enter your email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea id="message" rows="5" placeholder="Enter your message" required></textarea>
                        </div>
                        <button type="submit" className="submit-btn">Send Message</button>
                    </form>
                </div>
                <div className="contact-details">
                    <h2>Contact Information</h2>
                    <p>Email: support@teamlucifer.com</p>
                    <p>Phone: +94 77 018 1562</p>
                    
                    <h2>Follow Us</h2>
                    <div className="social-links">
                        <a href="https://www.youtube.com/@SL_Lucif3R" className="social-btn youtube" target="_blank" rel="noreferrer">
                            <FaYoutube /> YouTube
                        </a>
                        <a href="https://www.twitch.tv/sl_lucif3r" className="social-btn twitch" target="_blank" rel="noreferrer">
                            <FaTwitch /> Twitch
                        </a>
                        <a href="https://web.facebook.com/watch/SLLucif3R/" className="social-btn facebook" target="_blank" rel="noreferrer">
                            <FaFacebookF /> Facebook
                        </a>
                        <a href="https://www.tiktok.com/@sllucif3r" className="social-btn tiktok" target="_blank" rel="noreferrer">
                            <FaTiktok /> TikTok
                        </a>
                        <a href="https://discord.gg/gDWZ3ruR" className="social-btn discord" target="_blank" rel="noreferrer">
                            <FaDiscord /> Discord
                        </a>
                        <a href="https://chat.whatsapp.com/Ck2G1UgWJ7v1G7c827btwC" className="social-btn whatsapp" target="_blank" rel="noreferrer">
                            <FaWhatsapp /> WhatsApp
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
