import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './ArticlePage.css';  // Import the CSS for styling

const ArticlePage = () => {
    const { id } = useParams();  // Get the article ID from the URL
    const [article, setArticle] = useState(null);

    // Fetch article data by ID
    const fetchArticle = async () => {
        try {
            const docRef = doc(db, 'News', id);  // Ensure collection name matches Firestore
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setArticle(docSnap.data());
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    };

    useEffect(() => {
        fetchArticle();
    }, [id]);

    return (
        <div className="article-page">
            {article ? (
                <>
                    <div className="article-banner-container">
                        <img src={article.imageUrl} alt={article.title} className="article-banner" />
                    </div>
                    <div className="article-content-container">
                        <h1 className="article-title">{article.title}</h1>
                        {article.author && <p className="article-author">By {article.author}</p>}
                        {article.publishedDate && <p className="article-date">{new Date(article.publishedDate).toDateString()}</p>}
                        {article.description && <p className="article-description">{article.description}</p>}
                        {article.category && <p className="article-category">Category: {article.category}</p>}
                        <div className="article-content">
                            {article.content}
                        </div>
                    </div>
                </>
            ) : (
                <p>Loading article...</p>
            )}
        </div>
    );
};

export default ArticlePage;
